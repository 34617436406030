import React, {useEffect, useState} from "react";
import "./About.scss";
import "../Prompts/Create/Create.scss";
import "../NavPageStyling.css";
import logo from "../../Images/stba_logo.svg";
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";
import API from "../../api/API";
import {Topic} from "../../models/Models";
import ReactGA from "react-ga";

const About = () => {
    const [topics, setTopics] = useState<Topic[]>([] as Topic[]);

    useEffect(() => {
        API.getTopics().then((response) => {
            if ("isError" in response) return;
            else setTopics(response);
        });
        ReactGA.pageview(window.location.pathname + window.location.search);
    });

    return (
        <>
            <Helmet>
                <title>About | Small Town * Big Stories</title>
            </Helmet>
            <div className="about">
                <h2>Small Town * Big Stories</h2>
                <p className={"intro-text"}>
                    A modern way to practice the tradition of storytelling #smalltownbigstories
                </p>
                <div className="responsive-embed">
                    <iframe
                        src={"https://www.youtube.com/embed/y-iwVF_IPCQ?rel=0&modestbranding=1&showinfo=0"}
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    />
                </div>
                <div className={"instructions"}>
                    <strong>Explore the stories of Wailuku</strong>
                    <div className={"topic-info-text"}>
                        <p>
                            Each value below includes storytelling activities to experience around Wailuku. Each topic
                            is centered around a single value inspired by the associated mural’s artist statement.
                        </p>
                        <p>
                            Inspired by the 'Ōlelo No'eau:
                            <br />
                            <i>
                                “I ho`okāhi ka umauma, ho`okāhi ke aloha.
                                <br />
                                All abreast together, one in love.”
                            </i>
                        </p>

                        <p>
                            <b>Select a topic to begin!</b>
                        </p>
                    </div>
                </div>
                {topics.length > 0 && (
                    <div>
                        {topics.map((topic) => {
                            const topicName = topic.name === "Kuleana" ? "Kuleana (Responsibility)" : topic.name;
                            return (
                                <Link
                                    className={"about-topics-buttons"}
                                    to={"/" + topic.name.toLowerCase()}
                                    key={topic.id}
                                >
                                    <strong>{topicName}</strong>
                                </Link>
                            );
                        })}
                    </div>
                )}
                <div className="bottom-part">
                    <img src={logo} alt={"Small Town Big Art Logo"} />
                    <p>
                        <strong>Small Town Big Art</strong> is a creative placemaking project that aims to develop a
                        public arts district focused on the distinctive sense of place, history, and culture of Wailuku,
                        Maui, Hawai‘i.
                    </p>
                </div>
            </div>
        </>
    );
};

export default About;
