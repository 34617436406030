import {useParams} from "react-router-dom";
import Go from "./Go/Go";
import TalkLanding from "./Talk/TalkLanding";
import CreateLanding from "./Create/CreateLanding";
import React from "react";
import About from "../About/About";

const PromptLanding = () => {
    const {promptType} = useParams();
    if (promptType === "talk") {
        return <TalkLanding />;
    } else if (promptType === "create") {
        return <CreateLanding />;
    } else if (promptType === "go") {
        return <Go />;
    } else return <About />;
};

export default PromptLanding;
