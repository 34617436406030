import React, {useEffect, useRef} from "react";
import {Link, useLocation, useParams} from "react-router-dom";
import "./ContinueTheStory.scss";
import BackArrow from "../../../components/BackArrow/BackArrow";
import Helmet from "react-helmet";
import photo from "../../../Images/Photo.png";
import playIcon from "../../../Images/icon-audio.svg";
import ReactGA from "react-ga";
import {Story} from "../../../models/Models";

const ContinueTheStory = () => {
    const {topicName} = useParams();
    const location = useLocation();
    const story = location.state as Story;
    const audioPlayer = useRef<HTMLAudioElement | null>(null);

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    });

    const togglePlay = () => {
        if (audioPlayer.current?.paused) {
            audioPlayer.current?.play();
        } else {
            audioPlayer.current?.pause();
        }
    };

    function checkMessage() {
        if (story.message === "<p></p>") {
            return "No Description";
        } else if (story.message === "") {
            return "No Description";
        } else {
            return story.message;
        }
    }

    return (
        <>
            <Helmet>
                <title>{topicName} | Create | Small Town * Big Stories</title>
            </Helmet>
            <div className={"ContinueTheStory"}>
                <div className="back-arrow-name">
                    <BackArrow />
                </div>
                <div className="prompt" id="sampleStory">
                    {story.media_type === "text" && (
                        <>
                            <div className={"text"}>
                                <p>
                                    <strong>QUESTION: </strong>
                                    {story.prompt!.text}
                                </p>
                                <p>
                                    <strong>SUBMITTED BY: </strong>
                                    {story.name}
                                </p>
                                <div>
                                    <strong>STORY: </strong>
                                    <div dangerouslySetInnerHTML={{__html: checkMessage()}} />
                                </div>
                            </div>
                        </>
                    )}
                    {story.media_type === "photo" && (
                        <>
                            <div className="promptsImage">
                                <img src={story.media!} alt="" />
                            </div>
                            <div className={"text"}>
                                <p>
                                    <strong>QUESTION: </strong>
                                    {story.prompt!.text}
                                </p>
                                <p>
                                    <strong>SUBMITTED BY: </strong> {story.name}
                                </p>
                                <div>
                                    <strong>DESCRIPTION: </strong>
                                    <div dangerouslySetInnerHTML={{__html: checkMessage()}} />
                                </div>
                            </div>
                        </>
                    )}
                    {story.media_type === "audio" && (
                        <>
                            <div className={"audioImagesContainer"}>
                                <p>Tap to Hear the Story</p>
                                <img className={"mediaControllerImg"} src={playIcon} alt={""} onClick={togglePlay} />
                            </div>
                            <audio ref={audioPlayer} src={story.media!} />
                            <div className={"text"}>
                                <p>
                                    <strong>QUESTION: </strong>
                                    {story.prompt!.text}
                                </p>
                                <p>
                                    <strong>SUBMITTED BY: </strong>
                                    {story.name}
                                </p>
                                <div>
                                    <strong>DESCRIPTION: </strong>
                                    <div dangerouslySetInnerHTML={{__html: checkMessage()}} />
                                </div>
                            </div>
                        </>
                    )}
                    {story.media_type === "video" && (
                        <>
                            <video controls>
                                <source src={story.media!} />
                            </video>
                            <div className="text">
                                <p>
                                    <strong>QUESTION: </strong>
                                    {story.prompt!.text}
                                </p>
                                <p>
                                    <strong>SUBMITTED BY: </strong>
                                    {story.name}
                                </p>
                                <div>
                                    <strong>DESCRIPTION: </strong>
                                    <div dangerouslySetInnerHTML={{__html: checkMessage()}} />
                                </div>
                            </div>
                        </>
                    )}
                </div>
                <h4 className={"arrive"}>Ready to submit your own story?</h4>
                <Link to={"/" + topicName + "/create"}>
                    <b className={"submit-button"}>Submit a story</b>
                </Link>
            </div>
        </>
    );
};

export default ContinueTheStory;
