import "../prompts-style.scss";
import React, {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import BackArrow from "../../../components/BackArrow/BackArrow";
import ReactGA from "react-ga";

import API from "../../../api/API";
import {Prompt} from "../../../models/Models";

const CreatePrompts = () => {
    const {topicName} = useParams();
    const [prompts, setPrompts] = useState<Prompt[]>([]);

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
        API.getTopicWithPromptsOfCard("create", topicName!).then((response) => {
            if ("isError" in response) {
                return;
            }
            if (response.prompts) setPrompts(response.prompts);
        });
    }, [topicName]);

    return (
        <>
            {prompts.length > 0 && (
                <div className="promptsPage">
                    <BackArrow label={"Create"} />
                    <h3>Choose One</h3>
                    <div className="promptList">
                        {prompts.map((prompt: any) => (
                            <div className={"simplePrompt"}>
                                <Link
                                    to={"/" + topicName + "/create/submit"}
                                    state={{text: prompt.text, promptId: prompt.id, card: prompt.card}}
                                >
                                    {prompt.text.toUpperCase()}
                                </Link>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </>
    );
};
export default CreatePrompts;
