import React, {useContext, useEffect, useState} from "react";
import "./Write.scss";
import "../Prompts/Create/Create.scss";
import "./ThankYouModal.scss";
import {Link, useLocation, useParams} from "react-router-dom";
import camera from "../../Images/black-camera-icon.svg";
import BackArrow from "../../components/BackArrow/BackArrow";
import logo from "../../Images/stba_logo.svg";
import {Helmet} from "react-helmet";
import ReactGA from "react-ga";
import API from "../../api/API";
import {StoryUpload} from "../../models/Models";
import {InputContext} from "../../components/InputContextProvider";

const Write = () => {
    const {topicName} = useParams();
    const [currentFile, setCurrentFile] = useState<File | null>(null);
    const [name, setName] = useState<string>("");
    const [message, setMessage] = useState<string>("");
    const [disable, setDisable] = useState(true);
    const location = useLocation();
    const state = location.state as {text: string; promptId: number; card: string};
    const {changeIsDirty} = useContext(InputContext);

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    });

    useEffect(() => {
        setImageUploadStyling();
    }, [currentFile]);

    useEffect(() => {
        if (message != "") {
            setDisable(false);
        } else {
            setDisable(true);
        }
    }, [message]);

    useEffect(() => {
        if (currentFile || message !== "" || name !== "") {
            changeIsDirty(true);
        } else {
            changeIsDirty(false);
        }
    }, [currentFile, message, name]);

    const setImageUploadStyling = () => {
        if (currentFile) {
            document.getElementById("fileLabel")!.classList.remove("upload-optional-photo-button");
            document.getElementById("fileLabel")!.classList.add("upload-photo");
        } else {
            document.getElementById("fileLabel")!.classList.add("upload-optional-photo-button");
            document.getElementById("fileLabel")!.classList.remove("upload-photo");
        }
    };

    const fileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            setCurrentFile(e.target.files[0]);
        }
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setDisable(true);
        const uploadPayload: StoryUpload = {
            topic_name: topicName!,
            prompt_id: state.promptId,
            card: state.card,
            message: message,
            name: name,
            media_type: currentFile ? "photo" : "text",
        };
        API.uploadStory(uploadPayload, currentFile).then((response) => {
            if ("isError" in response) {
                window.alert(
                    `Could not upload your story, try again or check your internet connection.\nError: ${response.code}\n${response.message}`
                );
                setDisable(false);
                return;
            }
            openModal("submit-write-modal");
        });
    };

    function openModal(modalID: string) {
        const modal = document.getElementById(modalID);
        if (modal != null) {
            modal.style.zIndex = "5";
            modal.style.display = "block";
        }
    }

    return (
        <>
            <Helmet>
                <title>{topicName} | Submit | Write | Small Town * Big Stories</title>
            </Helmet>
            <div className={"write-uploader"}>
                <div className={"form-layout"}>
                    <div className={"arrow-and-topic"}>
                        <BackArrow label={"Select Method"} />
                    </div>
                    {state && (
                        <div className={"prompt"}>
                            <strong>{state.text.toUpperCase()}</strong>
                        </div>
                    )}
                    <form className={"form-items"} onSubmit={(e) => handleSubmit(e)}>
                        <textarea
                            required={true}
                            name="description"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            className="write"
                            id="write"
                            cols={6}
                            rows={6}
                            placeholder="Type your story here..."
                        />
                        <input id="file" type="file" accept="image/*" onChange={(e) => fileChange(e)} hidden />
                        <label id="fileLabel" className={"upload-optional-photo-button"} htmlFor="file">
                            {!currentFile && (
                                <>
                                    <img src={camera} alt="" />
                                    <p>Add a Photo (Optional)</p>
                                </>
                            )}
                            {currentFile && <img alt="" src={URL.createObjectURL(currentFile)} />}
                        </label>
                        {currentFile && (
                            <div className={"retry-button"}>
                                <strong>Click Above to Retry</strong>
                            </div>
                        )}
                        <input
                            className="name"
                            id="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            placeholder={"Add your name here"}
                        />
                        <p className={"name-sentence"}>(leave blank to submit anonymously)</p>
                        <div className="checkbox">
                            <label>
                                By pressing "submit", you consent to the public sharing of your story by ST*BA.
                            </label>
                        </div>
                        <button className="submit-button" disabled={disable}>
                            <b>Submit</b>
                        </button>
                    </form>
                    <div className="modal" id="submit-write-modal">
                        <div className="thank-you-modal">
                            <br />
                            <div className="modal-header">Thank you for contributing!</div>
                            <img alt="" src={logo} className="modal-image" />
                            <div className="modal-little-text">
                                Search for other cards around Wailuku, or continue to explore other story topics.
                            </div>
                            <Link to="/about" className="modal-return-button">
                                Continue Exploring
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Write;
