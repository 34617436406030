import {ReactComponent as Talk} from "../../Images/icon-talk.svg";
import {ReactComponent as Go} from "../../Images/icon-go.svg";
import {ReactComponent as Create} from "../../Images/icon-create.svg";
import {ReactComponent as About} from "../../Images/icon-about.svg";
import {NavLink, Routes, Route, useLocation} from "react-router-dom";
import React, {useContext} from "react";
import {InputContext} from "../InputContextProvider";

import "./Footer.scss";
import {useEffect} from "react";

const Footer = () => {
    const topicName = useLocation().pathname.split("/")[1];
    const numberOfRoutes = useLocation().pathname.split("/").length - 1;
    const {isDirty, changeIsDirty} = useContext(InputContext);

    useEffect(() => {
        const footer = document.getElementById("footer-id");
        if (footer) {
            setFooterColor(footer);
            if (numberOfRoutes === 1) {
                footer.classList.add("dynamic-positioning");
                if (topicName == "about") {
                    footer.style.display = "none";
                } else {
                    footer.style.display = "flex";
                }
            } else {
                footer.classList.remove("dynamic-positioning");
            }
        }
    });

    const setFooterColor = (footer: HTMLElement) => {
        switch (topicName) {
            case "diversity":
                footer.style.borderBottom = "10px solid #7d5c8e";
                break;
            case "legacy":
                footer.style.borderBottom = "10px solid #ef780d";
                break;
            case "tradition":
                footer.style.borderBottom = "10px solid #345f95";
                break;
            case "protection":
                footer.style.borderBottom = "10px solid #db99aa";
                break;
            case "hope":
                footer.style.borderBottom = "10px solid #99c4di";
                break;
            case "kuleana":
                footer.style.borderBottom = "10px solid #515f6e";
                break;
            case "belonging":
                footer.style.borderBottom = "10px solid #76388f";
                break;
            default:
                footer.style.borderBottom = "10px solid #D2F1FA";
                break;
        }
    };

    const warnBeforeNav = (e: React.MouseEvent<HTMLAnchorElement>) => {
        if (
            isDirty &&
            !window.confirm("Are you sure you want to leave? You will lose the current progress on your story.")
        ) {
            e.preventDefault();
        } else {
            changeIsDirty(false);
        }
    };

    return (
        <div className="app-footer" id="footer-id">
            <div className="dynamic-footer-buttons">
                <Routes>
                    <Route
                        path="/:topicName/:promptType/*"
                        element={
                            <>
                                <NavLink to={"/" + topicName + "/talk"} onClick={warnBeforeNav}>
                                    <Talk />
                                </NavLink>
                                <NavLink to={"/" + topicName + "/go"} onClick={warnBeforeNav}>
                                    <Go />
                                </NavLink>
                                <NavLink to={"/" + topicName + "/create"} onClick={warnBeforeNav}>
                                    <Create />
                                </NavLink>
                            </>
                        }
                    />
                </Routes>
            </div>
            <div className="icons">
                <div className="background-light-blue-highlight">
                    <NavLink to="/about" onClick={warnBeforeNav}>
                        <About className="about-button" />
                    </NavLink>
                </div>
            </div>
        </div>
    );
};

export default Footer;
