import React, {createContext, useState} from "react";

type InputContextProvider = {
    isDirty: boolean;
    changeIsDirty: (value: boolean) => void;
};

export const InputContext = createContext<InputContextProvider>(null!);

const InputContextProvider: React.FC = ({children}) => {
    const [isDirty, setIsDirty] = useState(false);

    const changeIsDirty = (value: boolean) => {
        setIsDirty(value);
    };

    return <InputContext.Provider value={{isDirty, changeIsDirty}}>{children}</InputContext.Provider>;
};

export default InputContextProvider;
