import "../prompts-style.scss";
import React, {useEffect, useRef, useState} from "react";
import leftTappingArrow from "../../../Images/arrow-left.svg";
import rightTappingArrow from "../../../Images/arrow-right.svg";
import promptsBird from "../../../Images/bird.svg";
import pauseIcon from "../../../Images/icon-audio.svg";
import {Link, useParams} from "react-router-dom";
import {Helmet} from "react-helmet";
import API from "../../../api/API";
import {Prompt} from "../../../models/Models";

const TalkLanding = () => {
    const audioPlayerRef = useRef<HTMLAudioElement>(null!);
    const {topicName} = useParams();
    const [prompts, setPrompts] = useState<Prompt[]>([] as Prompt[]);
    const [currentPrompt, setCurrentPrompt] = useState(0);
    const [touchStart, setTouchStart] = useState(0);

    useEffect(() => {
        API.getTopicWithPromptsOfCard("talk", topicName!).then((topic) => {
            if ("isError" in topic) {
                return;
            }
            setPrompts(topic.prompts!);
        });
    }, [topicName]);

    const moveRight = () => {
        if (currentPrompt < prompts.length - 1) {
            setCurrentPrompt(currentPrompt + 1);
        } else {
            setCurrentPrompt(0);
        }
    };

    const moveLeft = () => {
        if (currentPrompt > 0) {
            setCurrentPrompt(currentPrompt - 1);
        } else {
            setCurrentPrompt(prompts.length - 1);
        }
    };

    const handleTouchStart = (event: React.TouchEvent<HTMLDivElement>) => {
        setTouchStart(event.changedTouches[0].screenX);
    };

    const handleTouchEnd = (event: React.TouchEvent<HTMLDivElement>) => {
        handleGesture(event.changedTouches[0].screenX);
    };

    const handleGesture = (touchendX: number) => {
        if (touchStart - touchendX > 20) {
            moveLeft();
        }

        if (touchendX - touchStart > 20) {
            moveRight();
        }
    };

    const togglePlay = () => {
        if (audioPlayerRef.current!.paused) {
            audioPlayerRef.current!.play().catch((e) => console.log(e));
        } else {
            audioPlayerRef.current!.pause();
        }
    };

    return (
        <>
            <Helmet>
                <title>{topicName} | Talk | Small Town * Big Stories</title>
            </Helmet>
            {prompts.length > 0 && (
                <div className="promptsPage">
                    <div className="page1">
                        <div className={"form-layout"}>
                            <h3 className="talk-landing-header">
                                Start a conversation
                                <br />
                                with someone nearby.
                            </h3>
                            <div className="slider" onTouchEnd={handleTouchEnd} onTouchStart={handleTouchStart}>
                                <div className="background-color-box" />
                                <img src={leftTappingArrow} onClick={moveLeft} className="arrows" alt="Previous" />
                                <div className="prompt">
                                    {prompts[currentPrompt].media_type === "text" && (
                                        <>
                                            <h3>{prompts[currentPrompt].text}</h3>
                                            <img src={promptsBird} alt="" />
                                        </>
                                    )}
                                    {prompts[currentPrompt].media_type === "photo" && prompts[currentPrompt].media && (
                                        <>
                                            <div className="promptsImage">
                                                <img src={prompts[currentPrompt].media!} alt="" />
                                            </div>
                                            <h3 className="prompts-with-image-text">{prompts[currentPrompt].text}</h3>
                                        </>
                                    )}
                                    {prompts[currentPrompt].media_type === "video" && prompts[currentPrompt].media && (
                                        <>
                                            <video controls>
                                                <source src={prompts[currentPrompt].media!} />
                                            </video>
                                            <h3 className="prompts-with-image-text">{prompts[currentPrompt].text}</h3>
                                        </>
                                    )}
                                    {prompts[currentPrompt].media_type === "audio" && prompts[currentPrompt].media && (
                                        <>
                                            <div className={"audioImagesContainer"}>
                                                <img
                                                    alt=""
                                                    className={"backImg"}
                                                    src={prompts[currentPrompt].thumbnail!}
                                                />
                                                <img
                                                    alt=""
                                                    className={"mediaControllerImg"}
                                                    src={pauseIcon}
                                                    onClick={togglePlay}
                                                />
                                            </div>
                                            <audio ref={audioPlayerRef} src={prompts[currentPrompt].media!} />
                                            <h3 className="prompts-with-image-text">{prompts[currentPrompt].text}</h3>
                                        </>
                                    )}
                                </div>
                                <img src={rightTappingArrow} onClick={moveRight} className="arrows" alt="Next" />
                            </div>
                            <ol className="paging">
                                {prompts.map((prompt: any, index: number) => (
                                    <li className={currentPrompt === index ? "active" : ""} />
                                ))}
                            </ol>
                            <h4>Please share your story with us!</h4>
                            <Link
                                to={"/" + topicName + "/talk/submit"}
                                state={{
                                    text: prompts[currentPrompt].text,
                                    promptId: prompts[currentPrompt].id,
                                    card: prompts[currentPrompt].card,
                                }}
                                className="submit-button"
                            >
                                <b>Submit a Story</b>
                            </Link>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};
export default TalkLanding;
