import React, {useEffect, useRef} from "react";
import {Link, useLocation, useParams} from "react-router-dom";
import "./Here.scss";
import BackArrow from "../../../components/BackArrow/BackArrow";
import playIcon from "../../../Images/icon-audio.svg";
import {Helmet} from "react-helmet";
import {Prompt} from "../../../models/Models";
import ReactGA from "react-ga";

const ImHere = () => {
    const {topicName} = useParams();
    const location = useLocation();
    const prompt = location.state as Prompt;
    const audioRef = useRef<HTMLAudioElement | null>(null);

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    });

    const toggleAudioPlaying = () => {
        if (audioRef.current!.paused) {
            audioRef.current!.play();
        } else {
            audioRef.current!.pause();
        }
    };

    return (
        <>
            <Helmet>
                <title>{topicName} | Here | Small Town * Big Stories</title>
            </Helmet>
            {prompt.location && (
                <div className={"imhere"}>
                    <div className={"go-header"}>{prompt.location.name}</div>
                    <BackArrow label={prompt.location.name} />
                    <div className={"audioImagesContainer"}>
                        <img alt="" className={"backImg"} src={prompt.thumbnail!} />
                        <img alt="" className={"mediaControllerImg"} src={playIcon} onClick={toggleAudioPlaying} />
                    </div>
                    <audio src={prompt.media!} controls={true} ref={audioRef} />
                    <p>{prompt.text}</p>
                    <div className={"arrive"}>
                        <p>Find a comfortable place to relax and listen to this story.</p>
                        <p>Then, please share a story of your own about this place.</p>
                    </div>
                    <Link
                        to={"/" + topicName + "/go/here/submit"}
                        state={{text: prompt.text, promptId: prompt.id, card: prompt.card}}
                    >
                        <button className={"submit-button"}>Submit a story</button>
                    </Link>
                </div>
            )}
        </>
    );
};

export default ImHere;
