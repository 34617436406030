import logo from "../../Images/stba_logo.svg";

import "./Header.scss";
import {Link, useLocation} from "react-router-dom";

function Header() {
    const topicName = useLocation().pathname.split("/")[1];
    const submitType = useLocation().pathname.split("/")[2];
    const prompt = useLocation().pathname.split("/")[1];
    const logoLink = prompt !== "about" ? "/" + prompt : "/about";

    if (submitType != undefined) {
        return (
            <header className="app-header">
                <Link to={logoLink}>
                    <h1>
                        <img src={logo} alt="Small Town Big Art Home" />
                        Small Town * Big Stories | {topicName.toUpperCase()}
                    </h1>
                </Link>
            </header>
        );
    } else {
        return (
            <header className="app-header">
                <Link to={logoLink}>
                    <h1>
                        <img src={logo} alt="Small Town Big Art Home" />
                        Small Town * Big Stories
                    </h1>
                </Link>
            </header>
        );
    }
}

export default Header;
