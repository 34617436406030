import React, {useEffect, useState} from "react";
import "./TopicLandingPage.scss";
import {Link, useParams} from "react-router-dom";
import {ReactComponent as Talk} from "../../Images/icon-talk.svg";
import {ReactComponent as Go} from "../../Images/icon-go.svg";
import {ReactComponent as Create} from "../../Images/icon-create.svg";
import talkPopUp from "../../Images/phone-screen.jpg";
import goPopUp from "../../Images/go-pop-up.jpg";
import createPopUp from "../../Images/create-pop-up.jpg";
import {Helmet} from "react-helmet";
import {Topic} from "../../models/Models";
import API from "../../api/API";

const TopicLandingPage = () => {
    const {topicName} = useParams();
    const [selection, setSelection] = useState("");
    const [topic, setTopic] = useState<Topic | null>(null);

    useEffect(() => {
        API.getTopicFromName(topicName!).then((topic) => {
            if ("isError" in topic) return;
            setTopic(topic);
        });
        document.body.style.overflow = selection ? "hidden" : "auto";
        return () => {
            document.body.style.overflow = "auto";
        };
    }, [selection]);

    return (
        <>
            <Helmet>
                <title>{topicName} | Small Town * Big Stories</title>
            </Helmet>
            {topic && (
                <div className="topic">
                    <h2>Let's Talk Story!</h2>
                    <div className={"intro-paragraph"}>
                        Small Town Big Stories is an immersive storytelling experience to enhance community and practice
                        tradition in a modern way.
                    </div>
                    <div className="responsive-embed">
                        <iframe
                            src={topic.video_url}
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        />
                    </div>
                    <div className={"intro-paragraph"} id={"link-label"}>
                        Select an activity to begin!
                    </div>
                    <ul>
                        <li>
                            <div onClick={() => setSelection("talk")} className="link-from-topic-page">
                                <div className="icon">
                                    <Talk />
                                    <strong>Talk</strong>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div onClick={() => setSelection("go")} className="link-from-topic-page">
                                <div className="icon">
                                    <Go />
                                    <strong>Go</strong>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div onClick={() => setSelection("create")} className="link-from-topic-page">
                                <div className="icon">
                                    <Create />
                                    <strong>Create</strong>
                                </div>
                            </div>
                        </li>
                    </ul>

                    {selection && (
                        <div className="overlay" onClick={() => setSelection("")}>
                            {selection === "talk" && (
                                <div className="overlay-content">
                                    <div className="overlay-header" onClick={(e) => e.stopPropagation()}>
                                        <button
                                            type={"button"}
                                            onClick={() => setSelection("")}
                                            className="overlay-close"
                                            title={"Close instructions"}
                                        >
                                            ×
                                        </button>
                                        <Talk />
                                        <strong>Talk</strong>
                                    </div>
                                    <div className="overlay-instructions">
                                        <img src={talkPopUp} alt="" className="overlay-image" />
                                        <p>Scroll through the prompts to start a conversation with those around you.</p>
                                        <Link to={"/" + topic.name + "/talk"}>Let’s Talk Story</Link>
                                    </div>
                                </div>
                            )}
                            {selection === "go" && (
                                <div className="overlay-content">
                                    <div className="overlay-header" onClick={(e) => e.stopPropagation()}>
                                        <button
                                            type={"button"}
                                            onClick={() => setSelection("")}
                                            className="overlay-close"
                                            title={"Close instructions"}
                                        >
                                            ×
                                        </button>
                                        <Go />
                                        <strong>Go</strong>
                                    </div>
                                    <div className="overlay-instructions">
                                        <img src={goPopUp} alt="" className="overlay-image" />
                                        <p>
                                            Travel to a destination in Maui to hear a story about that place from a
                                            community member.
                                        </p>
                                        <Link to={"/" + topic.name + "/go"}>Let’s Go!</Link>
                                    </div>
                                </div>
                            )}
                            {selection === "create" && (
                                <div className="overlay-content">
                                    <div className="overlay-header" onClick={(e) => e.stopPropagation()}>
                                        <button
                                            type={"button"}
                                            onClick={() => setSelection("")}
                                            className="overlay-close"
                                            title={"Close instructions"}
                                        >
                                            ×
                                        </button>
                                        <Create />
                                        <strong>Create</strong>
                                    </div>
                                    <div className="overlay-instructions">
                                        <img src={createPopUp} alt="" className="overlay-image" />
                                        <p>
                                            View and share the stories of your community. Scroll through story
                                            submissions by locals, and share your own if you wish. Everyone has stories
                                            to tell.
                                        </p>
                                        <Link to={"/" + topic.name + "/create"}>Browse Stories!</Link>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

export default TopicLandingPage;
