import {Prompt, Story, Topic, StoryUpload} from "../models/Models";

export type APIError = {
    isError: boolean;
    code: number;
    codeText: string;
    message?: string;
};

const BASE_URL = "https://stories-api.apsoprojects.org";

const getTopics = async (): Promise<Topic[] | APIError> => {
    return await fetch(`${BASE_URL}/topics`)
        .then((response) => {
            if (response.ok) return response.json();
            return {
                isError: true,
                code: response.status,
                codeText: response.statusText,
                message: response.statusText,
            };
        })
        .catch((error) => {
            return {
                isError: true,
                code: 400,
                message: error.message,
            };
        });
};

const getTopicFromName = async (name: string): Promise<Topic | APIError> => {
    return await fetch(`${BASE_URL}/topics/${name}`)
        .then((response) => {
            if (response.ok) return response.json();
            return {
                isError: true,
                code: response.status,
                codeText: response.statusText,
                message: response.statusText,
            };
        })
        .catch((error) => {
            return {
                isError: true,
                code: 400,
                message: error.message,
            };
        });
};

const getTopicWithPrompts = async (name: string): Promise<Topic | APIError> => {
    return await fetch(`${BASE_URL}/topics/${name}/prompts`)
        .then((response) => {
            if (response.ok) return response.json();
            return {
                isError: true,
                code: response.status,
                codeText: response.statusText,
                message: response.statusText,
            };
        })
        .catch((error) => {
            return {
                isError: true,
                code: 400,
                message: error.message,
            };
        });
};

const getTopicWithStories = async (name: string): Promise<Topic | APIError> => {
    return await fetch(`${BASE_URL}/topics/${name}/stories`)
        .then((response) => {
            if (response.ok) return response.json();
            return {
                isError: true,
                code: response.status,
                codeText: response.statusText,
                message: response.statusText,
            };
        })
        .catch((error) => {
            return {
                isError: true,
                code: 400,
                message: error.message,
            };
        });
};

const getTopicWithPromptsOfCard = async (card: string, name: string): Promise<Topic | APIError> => {
    return await fetch(`${BASE_URL}/topics/${name}/prompts/${card}`)
        .then((response) => {
            if (response.ok) return response.json();
            return {
                isError: true,
                code: response.status,
                codeText: response.statusText,
                message: response.statusText,
            };
        })
        .catch((error) => {
            return {
                isError: true,
                code: 400,
                message: error.message,
            };
        });
};

const getSinglePrompt = async (promptId: number): Promise<Prompt | APIError> => {
    return await fetch(`${BASE_URL}/prompts/${promptId}`)
        .then((response) => {
            if (response.ok) return response.json();
            return {
                isError: true,
                code: response.status,
                codeText: response.statusText,
                message: response.statusText,
            };
        })
        .catch((error) => {
            return {
                isError: true,
                code: 400,
                message: error.message,
            };
        });
};

const uploadStory = async (storyUpload: StoryUpload, file?: File | Blob | null): Promise<Story | APIError> => {
    const uploadBody = new FormData();
    Object.keys(storyUpload).forEach((key) => {
        uploadBody.append(key, storyUpload[key]);
    });
    if (file) uploadBody.append("file", file);
    return await fetch(`${BASE_URL}/stories`, {
        method: "POST",
        body: uploadBody,
    })
        .then((response) => {
            if (response.ok) return response.json();
            return {
                isError: true,
                code: response.status,
                codeText: response.statusText,
                message: response.statusText,
            };
        })
        .catch((error) => {
            return {
                isError: true,
                code: 400,
                message: error.message,
            };
        });
};

const API = {
    getTopics,
    getSinglePrompt,
    getTopicFromName,
    getTopicWithPrompts,
    getTopicWithPromptsOfCard,
    getTopicWithStories,
    uploadStory,
};
export default API;
