import React, {useContext, useEffect, useRef, useState} from "react";
import "../Prompts/Create/Create.scss";
import "./ThankYouModal.scss";
import microphone from "../../Images/microphone-with-circle-icon.svg";
import recording from "../../Images/record-icon.svg";
import preview from "../../Images/play-button-icon.svg";
import {Link, useLocation, useParams} from "react-router-dom";
import BackArrow from "../../components/BackArrow/BackArrow";
import logo from "../../Images/stba_logo.svg";
import pauseIcon from "../../Images/pause-button-icon.svg";
import trashIcon from "../../Images/trashCan.svg";
import {Helmet} from "react-helmet";
import API from "../../api/API";
import ReactGA from "react-ga";
import {StoryUpload} from "../../models/Models";
import {InputContext} from "../../components/InputContextProvider";

let mediaRecorder: MediaRecorder;
let stream: MediaStream;
let blobs: Array<Blob> = [];

const UploaderTalk = () => {
    const {topicName} = useParams();
    const [currentFile, setCurrentFile] = useState<File | Blob | null>(null);
    const [icons, setIcons] = useState(microphone);
    const [name, setName] = useState<string>("");
    const [message, setMessage] = useState<string>("");
    const [trashShow, setTrashShow] = useState(false);
    const [deleteBlob, setDeleteBlob] = useState(false);
    const [durationStart, setDurationStart] = useState(0);
    const [durationEnd, setDurationEnd] = useState(0);
    const [duration, setDuration] = useState(0);
    const [playbackDuration, setPlaybackDuration] = useState(0);
    const [disabled, setDisabled] = useState(true);
    const {changeIsDirty} = useContext(InputContext);

    useEffect(() => {
        if (deleteBlob) {
            doPreview();
            setDeleteBlob(false);
            blobs = [];
        }
    }, [deleteBlob]);

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    });
    useEffect(() => {
        if (trashShow) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    }, [trashShow]);

    useEffect(() => {
        changeIsDirty(trashShow || message !== "" || name !== "");
    }, [trashShow, message, name]);

    useEffect(() => {
        if (durationStart != 0 && durationEnd != 0) {
            setDuration(Math.floor((durationEnd - durationStart) / 1000));
        }
    }, [durationEnd]);

    async function startRecording() {
        stream = await navigator.mediaDevices.getUserMedia({audio: true});
        mediaRecorder = new MediaRecorder(stream);
        mediaRecorder.ondataavailable = (event) => {
            // Append blobs for now, we could also upload them to the network.
            if (event.data) blobs.push(event.data);
        };
        mediaRecorder.onstop = doPreview;
        // Receive 1 second blobs
        mediaRecorder.start(1000);
        const date = new Date();
        setDurationStart(date.getTime());
    }

    function endRecording() {
        mediaRecorder.stop();
        const date = new Date();
        setDurationEnd(date.getTime());
        stream.getTracks().forEach((track) => track.stop());
        setTrashShow(true);
    }

    async function doPreview() {
        const audio = document.getElementById("audio") as HTMLAudioElement;
        if (deleteBlob) {
            URL.revokeObjectURL(audio.src);
            audio.src = "";
            return;
        }
        if (!blobs.length) return;
        // Concatenate blobs to preview the recorded content
        if (audio !== null) {
            const mp3Blob = new Blob(blobs, {type: mediaRecorder.mimeType});
            const fileURL = URL.createObjectURL(mp3Blob);
            setCurrentFile(mp3Blob);
            audio.src = fileURL;
        }
    }

    function iconChange() {
        if (icons === microphone) {
            setIcons(recording);
            startRecording().then();
        } else if (icons === recording) {
            setIcons(preview);
            endRecording();
            doPreview();
        } else if (icons === preview || icons === pauseIcon) {
            togglePlay();
        }
    }

    const audioPlayer = useRef<HTMLAudioElement>(null!);

    const togglePlay = () => {
        if (audioPlayer.current?.paused) {
            audioPlayer.current?.play();
            setIcons(pauseIcon);
        } else {
            audioPlayer.current?.pause();
            setIcons(preview);
        }
    };

    function switchThroughText() {
        if (icons === microphone) {
            return "Tap on the microphone to start recording your story!";
        } else if (icons === recording) {
            return "You are now recording your story.";
        } else if (icons === preview || icons === pauseIcon) {
            return "Preview your recording.";
        }
    }

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setDisabled(true);
        const uploadPayload: StoryUpload = {
            media_type: "audio",
            prompt_id: state.promptId,
            topic_name: topicName!,
            card: state.card,
            message: message,
            name: name,
        };
        API.uploadStory(uploadPayload, currentFile).then((response) => {
            if ("isError" in response) {
                window.alert(
                    `Could not upload your story, try again or check your internet connection.\nError: ${response.code}\n${response.message}`
                );
                setDisabled(false);
                return;
            }
            openModal("submit-audio-modal");
        });
    };

    function openModal(modalID: string) {
        const modal = document.getElementById(modalID);
        if (modal != null) {
            modal.style.zIndex = "5";
            modal.style.display = "block";
        }
    }

    const location = useLocation();
    const state = location.state as {text: string; promptId: number; card: string};

    const deleteAudio = () => {
        setPlaybackDuration(0);
        setDeleteBlob(true);
        setIcons(microphone);
        setTrashShow(false);
    };

    const handleAudioEnd = () => {
        setIcons(preview);
        setPlaybackDuration(0);
    };

    const changePlaybackTime = () => {
        setPlaybackDuration(audioPlayer.current?.currentTime!);
    };

    return (
        <>
            <Helmet>
                <title>{topicName} | Submit | Record | Small Town * Big Stories</title>
            </Helmet>
            <div className="uploader">
                <div className={"form-layout"}>
                    <div className={"arrow-and-topic"}>
                        <BackArrow label={"Select Method"} />
                    </div>
                    {state ? (
                        <div className="prompt">
                            <strong>{state ? state.text.toUpperCase() : <></>}</strong>
                        </div>
                    ) : (
                        <></>
                    )}
                    <h3 className="switching-through-text">{switchThroughText()}</h3>
                    <form className={"form-items"} onSubmit={(e) => handleSubmit(e)}>
                        <button className="upload-recording" type="button" onClick={iconChange}>
                            <img className={"audioPlayerIcons"} src={icons} alt="Record" />
                        </button>
                        <p>
                            <span className={trashShow ? "" : "hidden"}>
                                {("0" + Math.floor((playbackDuration / 60) % 60)).slice(-2)}:
                            </span>
                            <span className={trashShow ? "" : "hidden"}>
                                {("0" + (Math.floor(playbackDuration) % 60)).slice(-2)} /{" "}
                            </span>
                            <span className={trashShow ? "" : "hidden"}>
                                {("0" + (Math.floor(duration / 60) % 60)).slice(-2)}:
                            </span>
                            <span className={trashShow ? "" : "hidden"}>
                                {("0" + (Math.floor(duration) % 60)).slice(-2)}
                            </span>
                        </p>
                        <audio
                            id="audio"
                            ref={audioPlayer}
                            onEnded={handleAudioEnd}
                            onTimeUpdate={changePlaybackTime}
                        />
                        <button className={trashShow ? "trash" : "hidden"} type="button" onClick={deleteAudio}>
                            <img className={trashShow ? "" : "hidden"} src={trashIcon} alt={"Delete"} />
                        </button>
                        <textarea
                            name="message"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            rows={5}
                            cols={10}
                            maxLength={1000}
                            placeholder="No words are necessary, but you can add a caption if you like. Type here."
                        />
                        <input
                            className={"name"}
                            id={"name"}
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            placeholder={"Add your name here"}
                        />
                        <p className={"name-sentence"}>(leave blank to submit anonymously)</p>

                        <div className="checkbox">
                            <label>
                                By pressing "submit", you consent to the public sharing of your story by ST*BA.
                            </label>
                        </div>
                        <button className="submit-button" disabled={disabled}>
                            <b>Submit</b>
                        </button>
                    </form>
                    <div className="modal" id="submit-audio-modal">
                        <div className="thank-you-modal">
                            <div className="modal-header">Thank you for contributing!</div>
                            <img src={logo} className="modal-image" alt="" />
                            <div className="modal-little-text">
                                Search for other cards around Wailuku, or continue to explore other story topics.
                            </div>
                            <Link to="/about" className="modal-return-button">
                                Continue Exploring
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default UploaderTalk;
