import React, {useEffect} from "react";
import "./SubmitPage.scss";
import {Link, useLocation, useParams} from "react-router-dom";
import BackArrow from "../../components/BackArrow/BackArrow";
import microphone from "../../Images/microphone-icon.svg";
import camera from "../../Images/camera-icon.svg";
import write from "../../Images/write-icon.svg";
import {Helmet} from "react-helmet";
import ReactGA from "react-ga";

const SubmitPage = () => {
    const {topicName, promptType} = useParams();
    const location = useLocation();
    const data = location.state;

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    });

    return (
        <>
            <Helmet>
                <title>{topicName} | Submit | Small Town * Big Stories</title>
            </Helmet>
            <div className="submit-button-page">
                <div className={"form-layout"}>
                    <BackArrow label={topicName} />
                    <h3>Everyone has their story to tell. Please share yours with your community.</h3>
                    <text>Select a method to tell your story.</text>
                    <div className="submit-buttons">
                        <Link
                            to={"/" + topicName + "/" + promptType + "/submit/talk"}
                            state={data}
                            className={"submit-button"}
                        >
                            <div className={"submit-button-data"}>
                                <img src={microphone} alt="" />
                                <strong>RECORD</strong>
                            </div>
                        </Link>
                        <Link
                            to={"/" + topicName + "/" + promptType + "/submit/record"}
                            state={data}
                            className={"submit-button"}
                        >
                            <div className={"submit-button-data"}>
                                <img src={camera} alt="" />
                                <strong>PHOTO</strong>
                            </div>
                        </Link>
                        <Link
                            to={"/" + topicName + "/" + promptType + "/submit/write"}
                            state={data}
                            className={"submit-button"}
                        >
                            <div className={"submit-button-data"}>
                                <img src={write} alt="" />
                                <strong>WRITE</strong>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SubmitPage;
