import "../prompts-style.scss";
import React, {useEffect, useRef, useState} from "react";
import leftTappingArrow from "../../../Images/arrow-left.svg";
import rightTappingArrow from "../../../Images/arrow-right.svg";
import {Link, useParams} from "react-router-dom";
import playIcon from "../../../Images/icon-audio.svg";
import {Helmet} from "react-helmet";
import plus from "../../../Images/CTSplus.svg";
import API from "../../../api/API";
import {Story} from "../../../models/Models";

const CreateLanding = () => {
    const {topicName} = useParams();
    const [stories, setStories] = useState<Story[]>([] as Story[]);
    const [currentStory, setCurrentStory] = useState(0);
    const [touchStart, setTouchStart] = useState(0);
    const audioPlayer = useRef<HTMLAudioElement | null>(null);

    useEffect(() => {
        setCurrentStory(0);
        API.getTopicWithStories(topicName!).then((response) => {
            if ("isError" in response) {
                return;
            }
            if (response.stories) {
                if (response.stories.length > 0) setStories(response.stories);
            }
        });
    }, [topicName]);

    const moveRight = () => {
        if (currentStory < stories.length - 1) {
            setCurrentStory(currentStory + 1);
        } else {
            setCurrentStory(0);
        }
    };

    const moveLeft = () => {
        if (currentStory > 0) {
            setCurrentStory(currentStory - 1);
        } else {
            setCurrentStory(stories.length - 1);
        }
    };

    const handleTouchStart = (event: React.TouchEvent<HTMLDivElement>) => {
        setTouchStart(event.changedTouches[0].screenX);
    };

    const handleTouchEnd = (event: React.TouchEvent<HTMLDivElement>) => {
        handleGesture(event.changedTouches[0].screenX);
    };

    const handleGesture = (touchendX: number) => {
        if (touchStart - touchendX > 20) {
            moveLeft();
        }
        if (touchendX - touchStart > 20) {
            moveRight();
        }
    };

    const togglePlay = () => {
        if (audioPlayer.current?.paused) {
            audioPlayer.current?.play();
        } else {
            audioPlayer.current?.pause();
        }
    };

    const checkMessage = () => {
        if (stories[currentStory].message === "<p></p>") {
            return "No Description";
        } else if (!stories[currentStory].message) {
            return "No Description";
        } else {
            return stories[currentStory].message;
        }
    };

    return (
        <>
            <Helmet>
                <title>{topicName} | Create | Small Town * Big Stories</title>
            </Helmet>
            <div className="promptsPage">
                <div className="page1">
                    <div className={"form-layout"}>
                        {stories.length < 1 && (
                            <div className="create-placeholder">
                                <h3>A note from Small Town * Big Art:</h3>
                                <p>
                                    This is where you will soon be able to view stories that were submitted by your
                                    community members.
                                </p>
                                <p>This tool is still very new, so we need your help to fill it with stories.</p>
                                <p>Submit your story below, and come back soon to see stories from your community.</p>
                            </div>
                        )}
                        {stories.length > 0 && (
                            <>
                                <h3 className="create-heading">Browse community stories and then share your own.</h3>
                                <div className="slider" onTouchEnd={handleTouchEnd} onTouchStart={handleTouchStart}>
                                    <div className="background-color-box" />
                                    <img src={leftTappingArrow} onClick={moveLeft} className="arrows" alt="Previous" />
                                    <div className="prompt" id="sampleStory">
                                        {stories[currentStory].media_type === "text" && (
                                            <>
                                                <div className={"text-only"}>
                                                    <p>
                                                        <strong>QUESTION: </strong>
                                                        {stories[currentStory].prompt!.text}
                                                    </p>
                                                    <p>
                                                        <strong>SUBMITTED BY: </strong>{" "}
                                                        {stories[currentStory].name
                                                            ? stories[currentStory].name
                                                            : "Anonymous"}
                                                    </p>
                                                    <div>
                                                        <strong>SNEAK PEEK: </strong>
                                                        <div
                                                            dangerouslySetInnerHTML={{
                                                                __html: stories[currentStory].message
                                                                    ? stories[currentStory].message.slice(0, 225)
                                                                    : "No Story",
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <Link
                                                    to={"/" + topicName + "/create/continue-the-story"}
                                                    className="continue-the-story"
                                                    state={{...stories[currentStory]}}
                                                >
                                                    <img src={plus} alt={""} />
                                                    <b>Continue The Story</b>
                                                </Link>
                                            </>
                                        )}
                                        {stories[currentStory].media_type === "photo" && stories[currentStory].media && (
                                            <>
                                                <div className="storiesImage">
                                                    <img src={stories[currentStory].media!} alt="" />
                                                </div>
                                                <div className={"text"}>
                                                    <p>
                                                        <strong>QUESTION: </strong>
                                                        {stories[currentStory].prompt!.text}
                                                    </p>
                                                    <p>
                                                        <strong>SUBMITTED BY: </strong>{" "}
                                                        {stories[currentStory].name
                                                            ? stories[currentStory].name
                                                            : "Anonymous"}
                                                    </p>
                                                    <div>
                                                        <strong>DESCRIPTION: </strong>
                                                        <div
                                                            dangerouslySetInnerHTML={{
                                                                __html: checkMessage().slice(0, 100),
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <Link
                                                    to={"/" + topicName + "/create/continue-the-story"}
                                                    className="continue-the-story"
                                                    state={{...stories[currentStory]}}
                                                >
                                                    <img src={plus} alt={""} />
                                                    <b>Continue The Story</b>
                                                </Link>
                                            </>
                                        )}
                                        {stories[currentStory].media_type === "audio" && stories[currentStory].media && (
                                            <>
                                                <div className={"audioImagesContainer"}>
                                                    <p>Tap to Hear the Story</p>
                                                    <img
                                                        className={"mediaControllerImg"}
                                                        src={playIcon}
                                                        onClick={togglePlay}
                                                        alt={""}
                                                    />
                                                </div>
                                                <audio ref={audioPlayer} src={stories[currentStory].media!} />
                                                <div className={"text"}>
                                                    <p>
                                                        <strong>QUESTION: </strong>
                                                        {stories[currentStory].prompt!.text}
                                                    </p>
                                                    <p>
                                                        <strong>SUBMITTED BY: </strong>{" "}
                                                        {stories[currentStory].name
                                                            ? stories[currentStory].name
                                                            : "Anonymous"}
                                                    </p>
                                                    <div>
                                                        <strong>DESCRIPTION: </strong>
                                                        <div
                                                            dangerouslySetInnerHTML={{
                                                                __html: checkMessage().slice(0, 100),
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <Link
                                                    to={"/" + topicName + "/create/continue-the-story"}
                                                    state={{...stories[currentStory]}}
                                                    className="continue-the-story"
                                                >
                                                    <img src={plus} alt={""} />
                                                    <b>Continue The Story</b>
                                                </Link>
                                            </>
                                        )}
                                        {stories[currentStory].media_type === "video" && stories[currentStory].media && (
                                            <>
                                                <video controls>
                                                    <source src={stories[currentStory].media!} />
                                                </video>
                                                <div className="text">
                                                    <p>
                                                        <strong>QUESTION: </strong>
                                                        {stories[currentStory].prompt!.text}
                                                    </p>
                                                    <p>
                                                        <strong>SUBMITTED BY: </strong>{" "}
                                                        {stories[currentStory].name
                                                            ? stories[currentStory].name
                                                            : "Anonymous"}
                                                    </p>
                                                    <div>
                                                        <strong>DESCRIPTION: </strong>
                                                        <div
                                                            dangerouslySetInnerHTML={{
                                                                __html: checkMessage().slice(0, 100),
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <Link
                                                    to={"/" + topicName + "/create/continue-the-story"}
                                                    className="continue-the-story"
                                                    state={{...stories[currentStory]}}
                                                >
                                                    <img src={plus} alt={""} />
                                                    <b>Continue The Story</b>
                                                </Link>
                                            </>
                                        )}
                                    </div>
                                    <img src={rightTappingArrow} onClick={moveRight} className="arrows" alt="Next" />
                                </div>
                                <ol className="paging">
                                    {stories.map((story, index) => (
                                        <li className={currentStory === index ? "active" : ""} key={story.id} />
                                    ))}
                                </ol>
                            </>
                        )}
                        <h4>Please share your story with us!</h4>
                        <Link to={"/" + topicName + "/create/prompts"} className="submit-button">
                            <b>Submit a Story</b>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CreateLanding;
