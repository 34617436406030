import React, {useEffect} from "react";
import "./App.scss";
import "./global.scss";
import UploadPhotoOrVideo from "./Pages/Submit/UploadPhotoOrVideo";
import UploaderTalk from "./Pages/Submit/UploaderTalk";
import TopicLandingPage from "./Pages/Topic/TopicLandingPage";
import {BrowserRouter as Router, Navigate, Route, Routes} from "react-router-dom";
import About from "./Pages/About/About";
import ImHere from "./Pages/Prompts/Go/ImHere";
import Footer from "./components/Footer/Footer";
import SubmitPage from "./Pages/Submit/SubmitPage";
import Write from "./Pages/Submit/Write";
import PromptLanding from "./Pages/Prompts/PromptLandingPage";
import Header from "./components/Header/Header";
import CreatePrompts from "./Pages/Prompts/Create/CreatePrompts";
import ContinueTheStory from "./Pages/Prompts/Create/ContinueTheStory";
import ReactGA from "react-ga";
import InputContextProvider from "./components/InputContextProvider";

function App() {
    useEffect(() => {
        ReactGA.initialize(" UA-211521125-2", {
            debug: true,
            titleCase: false,
            gaOptions: {
                userId: "123",
            },
        });
    });

    return (
        <div className="App">
            <Router>
                <InputContextProvider>
                    <Header />
                    <main>
                        <Routes>
                            <Route path={"*"} element={<Navigate to={"/about"} />} />
                            <Route path={"/"} element={<Navigate to={"/about"} />} />
                            <Route path="/*/topic" element={<Header />} />
                            <Route path="/about" element={<About />} />
                            <Route path="/:topicName" element={<TopicLandingPage />} />
                            <Route path="/:topicName/:promptType" element={<PromptLanding />} />
                            <Route path="/:topicName/:promptType/here" element={<ImHere />} />
                            <Route path="/:topicName/:promptType/prompts" element={<CreatePrompts />} />
                            <Route path="/:topicName/:promptType/continue-the-story" element={<ContinueTheStory />} />
                            <Route path="/:topicName/:promptType/here/submit" element={<SubmitPage />} />
                            <Route path="/:topicName/:promptType/:subPrompt_id/submit" element={<SubmitPage />} />
                            <Route path="/:topicName/:promptType/submit" element={<SubmitPage />} />
                            <Route path="/:topicName/:promptType/submit/record" element={<UploadPhotoOrVideo />} />
                            <Route path="/:topicName/:promptType/submit/write" element={<Write />} />
                            <Route path="/:topicName/:promptType/submit/talk" element={<UploaderTalk />} />
                            <Route
                                path="/:topicName/:promptType/:subPrompt_id/submit/record"
                                element={<UploadPhotoOrVideo />}
                            />
                            <Route path="/:topicName/:promptType/:subPrompt_id/submit/write" element={<Write />} />
                            <Route
                                path="/:topicName/:promptType/:subPrompt_id/submit/talk"
                                element={<UploaderTalk />}
                            />
                        </Routes>
                    </main>
                    <Footer />
                </InputContextProvider>
            </Router>
        </div>
    );
}

export default App;
