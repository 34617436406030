import React, {useContext, useEffect, useState} from "react";
import "../Prompts/Create/Create.scss";
import "./ThankYouModal.scss";
import {Link, useLocation, useParams} from "react-router-dom";
import camera from "../../Images/camera.png";
import BackArrow from "../../components/BackArrow/BackArrow";
import logo from "../../Images/stba_logo.svg";
import {Helmet} from "react-helmet";
import API from "../../api/API";
import ReactGA from "react-ga";
import {StoryUpload} from "../../models/Models";
import {InputContext} from "../../components/InputContextProvider";

const UploadPhotoOrVideo = () => {
    const {topicName} = useParams();
    const [currentFile, setCurrentFile] = useState<File | null>(null);
    const [name, setName] = useState<string>("");
    const [message, setMessage] = useState<string>("");
    const fileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            setCurrentFile(e.target.files[0]);
        }
    };
    const [disabled, setDisabled] = useState(true);
    const {changeIsDirty} = useContext(InputContext);

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    useEffect(() => {
        if (currentFile) {
            setDisabled(false);
        }
    }, [currentFile]);

    useEffect(() => {
        if (currentFile || message != "" || name != "") {
            changeIsDirty(true);
        } else {
            changeIsDirty(false);
        }
    }, [currentFile, message, name]);

    function isFileImage(file: File) {
        return file && file["type"].split("/")[0] === "image";
    }

    function isFileVideo(file: File) {
        return file.type.match("video.*");
    }

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setDisabled(true);
        const uploadPayload: StoryUpload = {
            media_type: isFileVideo(currentFile!) ? "video" : "photo",
            prompt_id: state.promptId,
            topic_name: topicName!,
            card: state.card,
            message: message,
            name: name,
        };
        API.uploadStory(uploadPayload, currentFile).then((response) => {
            if ("isError" in response) {
                window.alert(
                    `Could not upload your story, try again or check your internet connection.\nError: ${response.code}\n${response.message}`
                );
                setDisabled(false);
                return;
            }
            openModal("submit-photo-video-modal");
        });
    };

    function openModal(modalID: string) {
        const modal = document.getElementById(modalID);
        if (modal != null) {
            modal.style.zIndex = "5";
            modal.style.display = "block";
        }
    }

    const location = useLocation();
    const state = location.state as {text: string; promptId: number; card: string};

    return (
        <>
            <Helmet>
                <title>{topicName} | Submit | Photo | Small Town * Big Stories</title>
            </Helmet>
            <div className={"uploader"}>
                <div className={"form-layout"}>
                    <div className={"arrow-and-topic"}>
                        <BackArrow label={"Select method"} />
                    </div>
                    {state && (
                        <div className={"prompt"}>
                            <strong>{state.text.toUpperCase()}</strong>
                        </div>
                    )}
                    <p id={"photo-or-vid"}>Take a Photo or Video!</p>
                    <form className={"form-items"} onSubmit={(e) => handleSubmit(e)}>
                        <input
                            id="file"
                            type="file"
                            accept=".heic/*,image/*,video/*"
                            onChange={(e) => fileChange(e)}
                            hidden
                        />
                        <label id="fileLabel" className={"upload-photo"} htmlFor="file">
                            {!currentFile && <img src={camera} alt="Choose a picture" />}
                            {currentFile && isFileImage(currentFile) && (
                                <img alt="" src={URL.createObjectURL(currentFile)} />
                            )}
                            {currentFile && isFileVideo(currentFile) && (
                                <video src={URL.createObjectURL(currentFile)} />
                            )}
                        </label>
                        {currentFile && (
                            <div className={"retry-button"}>
                                <strong>Click Above to Retry</strong>
                            </div>
                        )}
                        <textarea
                            name="description"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            rows={5}
                            cols={10}
                            maxLength={1000}
                            placeholder="No words are necessary, but you can add a caption if you like. Type here."
                        />
                        <input
                            className="name"
                            id="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            placeholder={"Add your name here"}
                        />
                        <p className={"name-sentence"}>(leave blank to submit anonymously)</p>
                        <div className="checkbox">
                            <label>
                                By pressing "submit", you consent to the public sharing of your story by ST*BA.
                            </label>
                        </div>
                        <button className="submit-button" disabled={disabled}>
                            <b>Submit</b>
                        </button>
                    </form>
                    <div className="modal" id="submit-photo-video-modal">
                        <div className="thank-you-modal">
                            <br />
                            <div className="modal-header">Thank you for contributing!</div>
                            <br />
                            <img alt="" src={logo} className="modal-image" />
                            <div className="modal-little-text">
                                Search for other cards around Wailuku, or continue to explore other story topics.
                            </div>
                            <Link to="/about" className="modal-return-button">
                                Continue Exploring
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default UploadPhotoOrVideo;
