import "./Go.scss";
// @ts-ignore
import {Pannellum} from "pannellum-react";
import {Link, useParams} from "react-router-dom";
import {Helmet} from "react-helmet";
import {useEffect, useState} from "react";
import ReactGA from "react-ga";
import {Prompt} from "../../../models/Models";
import API from "../../../api/API";

const Go = () => {
    const {topicName} = useParams();
    const [prompt, setPrompt] = useState<Prompt | null>(null);

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
        API.getTopicWithPromptsOfCard("go", topicName!).then((response) => {
            if ("isError" in response) {
                return;
            }
            if (response.prompts) {
                if ("location" in response.prompts[0]) {
                    setPrompt(response.prompts[0]);
                }
            }
        });
    }, [topicName]);

    return (
        <>
            <Helmet>
                <title>{topicName} | Go | Small Town * Big Stories</title>
            </Helmet>
            {prompt && prompt.location && (
                <div className={"go"}>
                    <div className={"go-header"}>
                        Visit the location below for an immersive storytelling experience.
                    </div>
                    <div className={"picture"}>
                        <Pannellum
                            width="100%"
                            height="100%"
                            image={prompt.location.media}
                            pitch={10}
                            yaw={180}
                            hfov={110}
                            autoLoad
                        />
                    </div>
                    <div className={"pano-bg"}>{prompt.location.name}</div>
                    <div className={"arrive"}>When you arrive, click here to hear a story.</div>
                    <Link to={"/" + topicName + "/go/here"} state={{...prompt}}>
                        <button className={"here-button"}>I'm Here!</button>
                    </Link>
                    <a
                        className={"directions-link"}
                        target="_blank"
                        href={`https://www.google.com/maps/search/?api=1&query=${prompt.location.latitude},${prompt.location.longitude}`}
                    >
                        <img alt="" src={prompt.location.map} />
                    </a>
                </div>
            )}
        </>
    );
};

export default Go;
