import backArrow from "../../Images/left 2.png";
import {useNavigate} from "react-router-dom";
import {InputContext} from "../InputContextProvider";
import "./BackArrow.scss";
import React, {useContext} from "react";

function BackArrow({label}: {label?: string}) {
    const navigate = useNavigate();
    const {isDirty, changeIsDirty} = useContext(InputContext);

    const warnBeforeGoBack = (e: React.MouseEvent<HTMLElement>) => {
        if (isDirty) {
            if (window.confirm("Are you sure you want to leave? You will lose the current progress on your story.")) {
                changeIsDirty(false);
                navigate(-1);
                return;
            }
            e.preventDefault();
        } else {
            navigate(-1);
        }
    };

    return (
        <div className="back-arrow" onClick={warnBeforeGoBack}>
            <img src={backArrow} alt="Back" />
            {label && <label>{label}</label>}
        </div>
    );
}

export default BackArrow;
